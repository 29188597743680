import {
  LibDeviceTypeMap,
  LibLendOutTypeMap
} from '@/admin-shared-modules/consts'
import {
  Lib,
  LibDeviceType,
  LibLendOutType,
  Tag
} from '@/admin-shared-modules/typings'
import { defaultErrorHandler } from '@/admin-shared-modules/utils'
import { GET, POST, PUT } from '@/admin-shared-modules/utils/ajax'
import { MessageService } from '@/admin-shared-modules/utils/message.service'
import { AbstractEditService } from '@/core/services/edit.service'
import { AbstractListService } from '@/core/services/list.service'
import { AbstractSearchService } from '@/core/services/search.service'
import { ListFetchOptions } from '@/core/typing'
import { LibResourceConfig, TopicTag } from '@/typing'
import _ from 'lodash'
import { reactive } from 'vue'
import { createLibResource } from '../lib-template/utils'

export interface LibFromData extends Lib {
  area: number[]
  tagBindKeys: number[]
  libTagBindKeys: number[]
  topicTagBind: TopicTag[]

  logo_source_type: 0 | 1
  app_image_source_type: 0 | 1
  tag_bind_source_type: 0 | 1
  lib_tag_bind_source_type: 0 | 1
  topic_tag_bind_source_type: 0 | 1

  lend_times: number
  read_book_times: number
  read_time_times: number
}

const mapDeviceTypeToVO = (data: number[]) => {
  return _.map(data, t => {
    return _.findKey(LibDeviceTypeMap, d => d === t)
  })
}
const mapDeviceTypeToDto = (data: LibDeviceType[]) => {
  return _.chain(data)
    .map(t => LibDeviceTypeMap[t])
    .filter(_.isNumber)
    .value()
}
const mapLendWayToVO = (data: number[]) => {
  return _.map(data, t => {
    return _.findKey(LibLendOutTypeMap, d => d === t)
  })
}
const mapLendWayToDto = (data: LibLendOutType[]) => {
  return _.chain(data)
    .map(t => LibLendOutTypeMap[t])
    .filter(_.isNumber)
    .value()
}

export const OrgInfoRegex = /^(\d+)(?:(?:[(（])(.+)(?:[)）]))?$/

class List extends AbstractListService<Store> {
  getFetchURL(): string {
    return 'library'
  }
  getSearchParams(): any {
    return this.context.search.getSearchParams()
  }
}

class Search extends AbstractSearchService<Store> {
  onSubmit(): void {
    this.context.list.fetch({ reset: true })
  }
  getSearchParams() {
    const data = this.data
    return {
      ...data,
      area_id: _.last(data.area)
    }
  }
}

class Edit extends AbstractEditService<Store, LibFromData> {
  getFetchURL(): string | boolean {
    return false
  }
  getRemoveURL(): string {
    return `library/${this.params.id}`
  }
  getSubmitURL(): string {
    return this.data.id ? `library/${this.data.id}` : 'library'
  }
  getSubmitMethod() {
    return this.data.id ? PUT : POST
  }

  requestListReload(option?: ListFetchOptions): void {
    this.context.list.fetch(option)
  }

  getDefaultFormData() {
    return {
      area: [],
      lend_days: 30,
      device_type: ['READER'],
      login_type: [1],
      lend_way: [],
      // qk_type: 0 as const,
      org_info: [],
      lib_ref: [],
      applications: [],
      is_usb: true,
      is_show_borrow: false,
      show_lend_info: true,
      is_lock: true,
      manage_config: 0,
      manage_config_pwd: '10988',
      enable_tts: false,

      // vo
      logo_source_type: 0 as const,
      app_image_source_type: 0 as const,
      tag_bind_source_type: 0 as const,
      lib_tag_bind_source_type: 0 as const,
      topic_tag_bind_source_type: 0 as const,
      lend_count: 0,
      read_time_count: 0
    } as Partial<LibFromData>
  }

  async onEdit(params: Partial<LibFromData>): Promise<void> {
    super.onEdit(params)
    this.loading = true
    await this.fetchBookTagsBind()
    await this.fetchTopicTagsBind()
    this.loading = false
  }

  parseFetchedFormData(data: any): LibFromData {
    const result = _.cloneDeep({
      ...data,
      area: [data.province_id, data.city_id, data.area_id],
      lib_ref: _.map(data.lib_ref, org => {
        return { schoolid: org.fid, name: org.org_name }
      }),
      device_type: mapDeviceTypeToVO(data.device_type),
      lend_way: mapLendWayToVO(data.lend_way),
      applications: data.applications || [],

      lend_days: 30,
      topicTagBind: [],

      lend_times: data.lend_times / 10 || 1,
      read_book_times: data.read_book_times / 10 || 1,
      read_time_times: data.read_time_times / 10 || 1,

      resource: this.detectResourceVersionAndReturn(data.resource),

      enable_tts: data.enable_tts || false
    })

    if (!this.isEdit) {
      const { bind_tags, bind_private_tags, bind_labels, shop_tab_name } =
        this.context.libTemplate.getTemplateValues()
      result.tagBindKeys = _.map<Tag>(bind_tags, 'tag_id')
      result.libTagBindKeys = _.map<Tag>(bind_private_tags, 'tag_id')
      result.shop_tab_name = shop_tab_name
      result.topicTagBind = [
        ..._.map(bind_labels.distribute, item => ({ ...item, checked: true })),
        ..._.map(bind_labels.not_distribute, item => ({
          ...item,
          checked: false
        }))
      ]
    }

    return result
  }

  detectResourceVersionAndReturn(
    resource?: LibResourceConfig
  ): LibResourceConfig {
    if (resource?.items) return resource
    return createLibResource()
  }

  async fetchBookTagsBind() {
    const { data: tagBinds } = await GET(`library/tag`, {
      data: {
        library_id: this.params.id,
        type: 1
      }
    })
    const { data: LibTagBinds } = await GET(`library/tag`, {
      data: {
        library_id: this.params.id,
        type: 2
      }
    })
    this.data.tagBindKeys = _.map<Tag>(tagBinds.items, 'tag_id')
    this.data.libTagBindKeys = _.map<Tag>(LibTagBinds.items, 'tag_id')
  }
  async fetchTopicTagsBind() {
    const { data } = await GET(`librarys/labels`, {
      data: {
        library_id: this.params.id
      }
    })
    this.data.topicTagBind = [
      ..._.map(data.distribute, item => ({ ...item, checked: true })),
      ..._.map(data.not_distribute, item => ({ ...item, checked: false }))
    ]
  }
  getSubmitData(): any {
    const data = _.cloneDeep(this.data)
    return {
      ...data,
      province_id: _.get(data.area, '[0]'),
      city_id: _.get(data.area, '[1]'),
      area_id: _.get(data.area, '[2]'),
      device_type: mapDeviceTypeToDto(data.device_type),
      lend_way: mapLendWayToDto(data.lend_way),
      org_info: _.chain(data.org_info)
        .filter('schoolid')
        .map(org => {
          return _.pick(org, ['dxfid', 'name', 'schoolid'])
        })
        .value(),
      lib_ref: _.chain<any>(data.lib_ref)
        .filter('schoolid')
        .map(org => {
          return { fid: org.schoolid, org_name: org.name }
        })
        .value(),
      lend_times: data.lend_times * 10 || 10,
      read_book_times: data.read_book_times * 10 || 10,
      read_time_times: data.read_time_times * 10 || 10,

      enable_tts: data.enable_tts ? 1 : 0
    }
  }

  async onEditSubmit() {
    if (this.saving) {
      return
    }
    this.saving = true
    const data = this.getSubmitData()
    try {
      const res = await this.getSubmitMethod()(this.getSubmitURL(), {
        data
      })

      // 用行业模版初始化机构
      if (!this.isEdit) {
        await this.context.libTemplate.fetch(res.data.lib_type)

        const templateData = _.pick(
          this.context.libTemplate.getTemplateValues(),
          [
            'login_type',
            'applications',
            'resource',
            'logo',
            'app_name',
            'app_image'
          ]
        )

        // LibTemplate.resource may be null
        templateData.resource = this.detectResourceVersionAndReturn(
          templateData.resource
        )

        this.data = this.parseFetchedFormData({
          ...this.getDefaultFormData(),
          ...res.data,
          ...templateData
        })
      } else {
        this.visible = false
        MessageService.open({ message: '保存成功' })
      }

      this.requestListReload()
    } catch (e) {
      defaultErrorHandler(e)
    } finally {
      this.saving = false
    }
  }
}

class LibTemplate {
  options = []
  data = null

  async fetchAll() {
    const { data } = await GET('lib_type', {})
    this.options = _.map(data, d => {
      return { key: d.id, value: d.name }
    })
  }
  async fetch(id) {
    const { data } = await GET(`lib_type/${id}`, {})
    this.data = data
  }
  getTemplateValues() {
    return this.data
  }
}

class Store {
  list: List
  search: Search
  edit: Edit

  libTemplate: LibTemplate

  constructor() {
    this.list = new List(this)
    this.search = new Search(this)
    this.edit = new Edit(this)
    this.libTemplate = new LibTemplate()
  }
}

export default reactive(new Store())
